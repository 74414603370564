import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { tokenExpired } from '@/helpers/authHelper'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/companies',
    name: 'companies.index',
    component: () => import('@/views/Companies/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fields',
    name: 'fields.index',
    component: () => import('@/views/Fields/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clients',
    name: 'clients.index',
    component: () => import('@/views/Clients/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clients/:id/edit',
    name: 'clients.edit',
    component: () => import('@/views/Clients/Edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/files',
    name: 'files.index',
    component: () => import('@/views/Files/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/devices',
    name: 'devices.index',
    component: () => import('@/views/Devices/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/groups',
    name: 'groups.index',
    component: () => import('@/views/Groups/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/groups/:id',
    name: 'groups.show',
    component: () => import('@/views/Groups/Edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/sandbox',
    name: 'sandbox.index',
    component: () => import('@/views/Sandbox/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (tokenExpired()) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
